@import '../variables';

$inverse-global-color-mode: none;

$global-font-family: $font-family;
$global-font-size: 1rem;

$global-primary-background: $primary-color;
$global-color: $base-color;
$global-muted-color: $muted-color;
$global-link-color: $base-color;
$global-link-hover-color: $primary-color;

$global-z-index: $z-index;
$global-border: $muted-color;

// Base
$base-body-background: $background;
$base-selection-background: $muted-color;
$base-link-hover-text-decoration: none;
$base-margin-vertical: 0;

// Button
$button-border-width: 0;
$button-primary-hover-color: $base-color;
$button-primary-active-color: $base-color;

// Card
$card-default-background: $card-background;
$card-body-padding-horizontal: $border-radius-large;
$card-body-padding-horizontal-l: $border-radius-xlarge;
$card-body-padding-vertical: $padding;
$card-body-padding-vertical-l: $padding;

// Container
$container-padding-horizontal: 0;
$container-padding-horizontal-s: $card-margin-small;
$container-padding-horizontal-m: $card-margin-small;

// Form
$form-padding-horizontal: $border-radius-medium;
$form-color: $control-color;
$form-focus-color: $control-color;

// Overlay
$overlay-default-background: $card-default-background;
$overlay-primary-background: $card-default-background;

// Offcanvas
$offcanvas-bar-width: 85%;
$offcanvas-bar-background: $card-default-background;
$offcanvas-overlay-background: rgba(black, 0.5);
$offcanvas-bar-padding-horizontal: calc(
	env(safe-area-inset-left) + #{$card-body-padding-horizontal}
);

// List
$list-margin-top: 0;

// Navbar
$navbar-nav-item-height: 0;
$navbar-nav-item-padding-horizontal: $card-body-padding-horizontal;
$navbar-nav-item-color: $primary-color;
$navbar-nav-item-hover-color: $primary-color;
$navbar-nav-item-onclick-color: $primary-color;

// Search
$search-icon-color: $muted-color;
$search-default-background: white;

// Slidenav
$slidenav-color: $muted-color;
$slidenav-hover-color: $primary-color;

// Text
$text-primary-color: $primary-color;

// Tooltip
$tooltip-border-radius: $border-radius-small;
