@use 'variables';
@use 'mixins';

body {
	background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="4" height="4"%3E%3Cpath fill="%23fff" fill-opacity="0.1" d="M1 3h1v1H1V3zm2-2h1v1H3V1z"/%3E%3C/svg%3E');
	min-height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
}

svg {
	margin-right: variables.$icon-margin;

	&.icon-center {
		margin: 0;
	}

	&.icon-right {
		margin-left: variables.$icon-margin;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
	font-size: 1.15rem;
}

.text-meta {
	color: variables.$muted-color;
	font-size: variables.$font-size-small;
}

.round-border {
	border-radius: variables.$border-radius-large;
}

.padding {
	padding: variables.$padding 0;
}

.padding-small {
	padding: variables.$padding-small 0;
}

.text-padding {
	padding: variables.$text-padding 0;
}

.padding-text {
	padding: variables.$padding-text 0;
}

.padding-text-top {
	padding-bottom: variables.$padding;
	padding-top: variables.$padding-text;
}

.padding-text-bottom {
	padding-bottom: variables.$padding-text;
	padding-top: variables.$padding;
}

.primary-border {
	@extend .round-border;
	border: 1px solid variables.$primary-color;
}

.border {
	border: 0.5px solid;
	border-radius: 5px;
}

@each $size,
	$font-size
		in (
			medium: variables.$font-size-medium,
			large: variables.$font-size-large,
			larger: variables.$font-size-larger,
			xlarge: variables.$font-size-xlarge
		)
{
	.text-#{$size} {
		font-size: $font-size;
	}
}
