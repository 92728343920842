@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/global';
@import '../../styles/mixins';

$size: 20px;
$padding: 2px;

.switch {
	border-radius: calc(#{$size} + 2 * #{$padding});
	cursor: pointer;
	display: flex;
	padding: $padding;
	width: 2 * $size;
	transition: background 0.3s ease;

	&.on {
		background: $primary-color;
	}

	&.off {
		background: $control-background;
	}
}

.slider {
	background: $control-color;
	border-radius: $size;
	padding: math.div($size, 2);
}
