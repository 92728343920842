@import '../../../../styles/variables';

$header-ratio: 0.45;

.header {
	align-items: center;
	display: flex;
	height: 6em;
	justify-content: center;
	margin-top: 2 * $card-margin-small;

	@media (min-width: $breakpoint-large) {
		margin-top: 2 * $card-margin-large;
	}

	&.large {
		height: calc(#{$header-ratio} * #{$viewport-height-small});
		margin: 0;
		min-height: 14em;

		@media (min-width: $breakpoint-medium) {
			height: calc(#{$header-ratio} * #{$viewport-height-medium});
		}

		@media (min-width: $breakpoint-large) {
			height: calc(#{$header-ratio} * #{$viewport-height-large});
		}
	}
}

.title {
	color: darken($primary-color, 5);
	font-size: 3.7em;
	font-weight: bold;
	filter: drop-shadow(0 1px 0 rgba(black, 0.15))
		drop-shadow(0 2px 1px rgba(black, 0.1))
		drop-shadow(0 4px 2px rgba(black, 0.05));
	transition: font-size 0.4s ease;

	.large & {
		margin-bottom: 3vh;

		@media (min-width: $breakpoint-small) {
			font-size: 6em;
		}
	}
}
