@import '../../../../styles/variables';

$ratio: 1 - $header-ratio;

.container {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 4em;
	text-align: center;
	
	@media (min-width: $breakpoint-medium) {
		height: calc(#{$ratio} * #{$viewport-height-medium} - #{$features-height});
	}
	
	@media (min-width: $breakpoint-large) {
		height: calc(#{$ratio} * #{$viewport-height-large} - #{$features-height});
	}
}

.arrowDown {
	border-style: solid;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 5px;
	transform: rotate(45deg),
}
