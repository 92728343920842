@import '../mixins';
@import './variables';
@import '../variables';

// Button
@mixin hook-button() {
	border-radius: $border-radius-large;
	height: 2.5rem;
	padding: 0;
	text-transform: uppercase;
	transform: scale(1);
	transition: all 0.2s ease;
	transition-property: background, color, transform, filter;

	&:active {
		transform: scale(0.9);
	}
}

.button {
	border-radius: $border-radius-large;
	height: 2.5rem;
}

@mixin hook-button-default() {
	@include button($gray);
}

@mixin hook-button-primary() {
	@include button(transparent, $primary-color);
	border: 1px solid $primary-color;
}

@mixin hook-button-primary-hover() {
	@include button($primary-color, $control-color);
}

@mixin hook-button-primary-active() {
	@include button(rgba($control-background, 0.5), $control-color);
	border: 1px solid transparent;
	filter: brightness(0.5);
}

.subnav {
	background: $control-background;
}

.success {
	background: $green;

	& + div {
		transition: color 0.3s ease-in;
		color: $control-color;
	}
}

.primary {
	background: $primary-color;

	& + div {
		transition: color 0.3s ease-in;
		color: $control-color;
	}
}

.danger {
	background: $red;

	& + div {
		transition: color 0.3s ease-in;
		color: $control-color;
	}
}

.sign {
	background: transparent;
	color: $control-color !important;

	&:hover {
		background: $muted-color;
	}
}

@mixin hook-button-misc() {
	.uk-button-secondary {
		@include button($gray);
	}

	.uk-button-info {
		@include button($blue);
	}

	.uk-button-success {
		@include button($green);
	}

	.uk-button-danger {
		@include button($red);
	}
}

// Card
@mixin hook-card() {
	border-radius: $border-radius-xlarge;
	margin: 2 * $card-margin-small $card-margin-small;
	overflow: hidden;
	transition: filter 0.2s ease;
	z-index: 0;
	border: 1px solid $muted-color;
	box-shadow: $shadow;

	@media (min-width: $breakpoint-large) {
		margin: 2 * $card-margin-large $card-margin-large;
	}
}

@mixin hook-card-default() {
	background: $card-background;
}

@mixin hook-card-default-hover() {
	@media (hover: hover) {
		filter: brightness(1.1);
	}
}

@mixin hook-card-body {
	padding: $padding;
}

// Form
@mixin hook-form() {
	@include button($control-background, $base-color);
	border-radius: $border-radius-large;
	font-size: 1.05em;
	transition: border 0.2s ease;
}

// Label
@mixin hook-label() {
	border-radius: $border-radius-small;
	font-weight: 500;
	text-transform: uppercase;
}

@mixin hook-label-success() {
	@include button(transparent, $green);
	border: 1px solid $green;
}

@mixin hook-label-warning() {
	@include hook-button-primary;
}

@mixin hook-label-danger() {
	@include button(transparent, $red);
	border: 1px solid $red;
}

// Navbar
@mixin hook-navbar-container() {
	padding: env(safe-area-inset-top) env(safe-area-inset-right) 0
		env(safe-area-inset-left);
	border-bottom: 1px solid $muted-color;
	box-shadow: $shadow;
}

@mixin hook-navbar() {
	padding: 0 $card-margin-small;

	@media (min-width: $breakpoint-large) {
		padding: 0 $card-margin-large;
	}

	li {
		position: relative;

		p {
			z-index: 0;
		}
	}
}

@mixin hook-navbar-nav-item() {
	height: $navbar-height-small;
	text-transform: uppercase;

	@media (min-width: $breakpoint-small) {
		height: $navbar-height-medium;
	}

	@media (min-width: $breakpoint-large) {
		height: $navbar-height-large;
		padding: 0 $card-body-padding-horizontal-l;
	}
}

@mixin hook-navbar-nav-item-hover() {
	color: $base-color;
}

// Offcanvas
@mixin hook-offcanvas-bar() {
	max-width: 25rem;
}

// Overlay
@mixin hook-overlay-default() {
	background: rgba(white, 0.05);
}

// Pagination
@mixin hook-pagination-item-hover() {
	color: $global-link-hover-color;
}

// Search
@mixin hook-search-default-input() {
	@include hook-form;
}

@mixin hook-search-misc() {
	.uk-search-input::placeholder {
		color: $muted-color;
	}

	.uk-search-icon {
		z-index: 1;
	}
}
