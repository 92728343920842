@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.container {
	list-style: none;
	padding: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	z-index: $z-index + 10;

	@media (max-width: $breakpoint-small) {
		left: 0;
	}
}

.notification {
	align-items: center;
	border-radius: $border-radius-large;
	cursor: pointer;
	pointer-events: all;
	display: flex;
	margin: $card-margin-small;
	padding: $text-padding $padding;
	box-shadow: $shadow;
	border: 1px solid $muted-color;
	color: white;

	@media (min-width: $breakpoint-small) {
		margin: $card-margin-large;
		width: 350px;
	}

	svg {
		margin-right: $padding;
	}
}

.info {
	background: $blue;
}

.success {
	background: $green;
}

.danger {
	background: $red;
}
