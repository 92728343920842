@use 'sass:math';

@use './variables';

@mixin button($background, $color: white) {
	&,
	&:hover,
	&:active {
		background: $background !important;
		color: $color !important;
	}
}
