@import '../../../../styles/variables';

.container {
	margin-bottom: 0;
	margin-top: 0;

	@media (min-width: $breakpoint-medium) {
		height: $features-height;
	}
}
